/**
*   button.scss
*   @description: Estilos para los botones
*/

/*---------------------- ESTILOS EN USO ------------------------*/

@mixin btn {
	cursor: pointer;
	font-family: $Mukta;
	font-size: $fontSmall;
	font-weight: $weightSemi;
	background-color: transparent;
	padding: $paddingMedium $paddingSemi;
	transition: all 200ms ease-in-out;
	&:active { transform: scale(0.95); }
}

@mixin hover-shine {
	text-shadow: 0px 0px 6px rgba(255, 255, 255, .5);
	transition: all 0.4s ease 0s;
}

.btn {
    cursor: pointer;
    font-family: $Mukta;
    font-size: $fontSmall;
    font-weight: $weightSemi;
    background-color: transparent;
    padding: $paddingMedium $paddingSemi;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
}

.btn-cancel {
	@include btn;
    border-bottom-right-radius: 4px;
	background: $secondary;
	color: white;
}

.btn-modal-delete-cancel {
	@include btn;
	width: 50%;
    border-bottom-right-radius: 4px;
	background: $secondary;
	color: white;

	&:hover {
		@include hover-shine;
	}
}

.btn-modal-delete-success {
	@include btn;
	width: 50%;
    border-bottom-left-radius: 4px;
	background: $primary;
	color: white;

	&:hover {
		@include hover-shine;
	}
}  

.btn-disable {
	border-radius: 2px;
	background-color: rgba($gray, 0.8) ;
    
}

/*----------------------------------------------------------------*/

button {
    cursor: pointer;
    font-family: $Mukta;
    background-color: transparent;
    &:disabled {
        cursor: not-allowed;
    }
}


.btn-mini {
    padding: 2px 7px;
    font-size: .8rem;
}

.btn-medium {
    width: 50%;
}

.btn-full{
    width: 100% !important;
}

.btn-primary {
    border-radius: 2px;
    background-color: $primary;
    &:hover { background-color: rgba($primary, 0.8); }
}

.btn-secondary {
	border-radius: 2px;
    background-color: $secondary;
    &:hover { background-color: rgba($secondary, 0.8)}
}

.btn-small {
    padding: $paddingNormal $paddingMedium;
}

.btn-filter {
    cursor: pointer;
    background-color: $black;
    color: $white;
    border-radius: 2px;
    font-size: $fontSmall;
    font-weight: $weightBold;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);*/
    transition: background-color 300ms ease-in-out;

    &:hover {
        background-color: $primary;
    }
}

.btn-transparent {
    transition: color 300ms ease-in-out;
    background-color: transparent;
    color: black;
    &:hover {
        background-color: transparent;
        color: $primary;
    }
}

.btn-buscar .btn-primary {
    margin-left: 6px;
    //background-color: $secondary;
}

.btn-horarios .btn-secondary {
    border-radius: 2px;
    color: white !important;
    background-color: $secondary !important;
    &:hover { background-color: rgba($secondary, 0.8); }
}

.btn-link:hover{
    color: $primary;
}