/**
*   card-incidents.scss
*   @description: Estilos de tarjetas de incidencias en la página de incidencias
*/

.card-incidents {

    .type { 
        width: 150px;
        padding-right: $paddingMedium;
    }

    .name { 
        width: 150px; 
    }

    .date {
        width: 100px;
        padding: 0 $paddingMedium;
    }

    .description { 
        width: 300px;
        padding: 0 $paddingMedium;
    }

    .resolution {
        width: 180px;
        padding: 0 $paddingMedium;
    }

    .more {
        width: 140px;
        padding: 0 $paddingNormal;
    }    

     

}