.paginator {
    .page {
        padding: 4px 13px;
        background-color: black;
        color: white;
        cursor: pointer;
        font-size: $fontSmall;
        font-weight: $weightBold;
        border-radius: 4px;
        margin: 4px;
        transition: background-color 300ms;

        &:hover {
            background-color: $primary;
        }
    }

    .active {
        background-color: $primary;
    }
}