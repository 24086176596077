

/*  INFORMATION
   =============================
    
	- File:           trejocode.css
	- Versión:        1.2
	- Licence:        Mozilla Public Licence. 
	- Author:         TrejoCode
    - Update:         11/06/2019
    - Client:         iGate
*/

/* RESET
   =============================
   - These properties able the cross compatibility for browsers and removes default properties from browsers.
*/

$flex: flex;

* {
    padding: 0;
    margin: 0;
}

html {
    font-size: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {    
    overflow-x: hidden;
    background-color: #F5F4F4;
}

input, textarea, select, option, button {
    margin: 0;
	border: 0;
    padding: 0;
    outline: none;
}

table {
    border-spacing: 0;
    overflow-x:  auto;
}

table th {
    white-space: nowrap;
}

input:focus, select:focus {
    outline: none;
    transition: 400ms ease-in-out;
}

ul, ol {
    list-style: none;
}

a {
    color: inherit;
    word-break: keep-all;
    text-decoration: none;        
    align-self: flex-start;
    cursor: pointer;
}

img {
    max-width: 100%;
    height: auto;
}

/*div, header, footer, section, article, aside, nav, form, table {
    width: 100%;
    transition: all 450ms;
}*/


/* CLASES
   =============================
	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = TABLES
    5. = IMAGES
    6. = DECORATORS & COLORS
*/


/*    FLEX LAYOUT
    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/

.flex {
    width: 100%;
    display: flex;
}

.row-reverse {
    flex-direction: row-reverse;
    display: flex;
}

.container {
    width: 90%;
    display: flex;
}

.responsive-img {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-responsive {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
    display: flex;
}


/*    ALIGNMENT    */


.justify-center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.justify-start {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.justify-end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.justify-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.justify-around {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.align-center {
    width: 100%;
    display: flex;
    align-items: center;
}

.align-start {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.align-end {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.auto {
    width: auto;    
    display: flex;
}

.medium{
    width: 50%;
    
}

.full {
    width: 100%;
}


/*    TEXT ALIGNMENT    */


.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}


/*    TABLES    */

.table-responsive {
    overflow-x: auto;
}

.table-responsive table{
    width: 100%;
}

.table-auto {
    table-layout: auto;
}

.table-equal {
    table-layout: fixed;
}
.parrafo{
    
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*    IMAGES    */

.cover-img {
    object-fit: cover;
}

.contain-img {
    object-fit: contain;
}


/*    DECORATORS    */

.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}



/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {

    .container {
        //max-width: 1440px;
    }

}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 992px) {
    
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    
    .row-responsive {
        flex-direction: column;
    }

    .white-space-64 {
        height: 32px;
    }
    
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    .container {
        width: 92%;
    }
}