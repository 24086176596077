#sidebar {
  height: 100%;
  width: 0;
  position: sticky;
  height: 100vh;
  z-index: 1;
  top: 0;
  color: $white;
  left: 0;
  background-color: $black;
  overflow-x: hidden;
  transition: 300ms;

  .accordion {
    background-color: $black;
    color: $white;
    cursor: pointer;
    min-width: 270px;
    width: 100%;
    position: relative;
    padding: 4px 16px;
    font-size: $fontRegular;
    border: none;
    text-align: left;
    outline: none;
    transition: background-color 0.3s ease-in-out;

    i {
      position: absolute;
      right: 18px;
      top: 12px;
    }
  }

  .active,
  .accordion:hover {
    background-color: $primary;
  }

  .panel {
    background-color: $black;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .content-side {
    width: 100%;
    .nav-item {
      padding-left: $paddingMedium;
      padding: 0px 32px;
      transition: 300ms;

      &:hover {
        background-color: #6c757d8a;
      }
    }

    .active-item {
      background-color: #6c757d8a;
    }
  }
}

#content {
  transition: margin-left 300ms;
  width: 100%;
}

.maxHeight {
  max-height: 136px !important;
}

/*LEFT SIDEBAR*/
.rightsidebar {
  position: fixed;
  right: 0px;
  background-color: $black;
  height: 100%;
  width: 0px;
  overflow-x: hidden;
  transition: 300ms;
  z-index: 1;
  padding-top: 100px;

  &.open {
    width: 280px;
    padding-left: 10px;
  }
}
.nav-exit:hover {
  color: $primary;
}
