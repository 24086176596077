
@mixin cards { 
    box-shadow: 0px 4px 7px #a0a0a082;
    background-color: $white;
    padding: 16px;

    h4,i {
        font-size: 1.45rem;     
    }
    i {
        font-size: 1.5rem;
        margin-right: 8px;
        margin-left: 8px;
        color: #EA545F;
    }
}

.interactions {
    .chartjs-render-monitor {
        margin-top: 10px;
        animation: chartjs-render-animation 0.001s;
        width: 100% !important;
        display: block;

        &:first-child {
            height: 500px !important;
        }
    }

    .grid-charts {
        display: grid;
        padding-top: 1.7rem;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 1.2rem;
    }

    .card-table {
        @include cards;
        width: 100%;

    }

    .card-table-big {
        @include cards;
        width: 100%;

        .chartjs-render-monitor {
            animation: chartjs-render-animation 0.001s;
            width: 100% !important;
            display: block;
            height: 500px !important;
        }

    }
}