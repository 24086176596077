/**
*   modals.scss
*   @description: Estilos para modales
*/

/*---------------------- ESTILOS EN USO ------------------------*/

@mixin modal-container {
	top: 50%;
    left: 50%; 
    width: 50%;
    outline: none;
    max-height: 90%;
    transition: max-height 1.5s ease-in-out;
   // overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    //padding: $paddingMedium;
    background-color: $white;
    transform: translate(-50%, -50%);
	z-index: 2;
}

.modal {
    top: 50%;
    left: 50%; 
    width: 50%;
    outline: none;
    max-height: 90%;
    transition: max-height 1.5s ease-in-out;
   // overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    //padding: $paddingMedium;
    background-color: $white;
    transform: translate(-50%, -50%);
	z-index: 2;

    .btn-container {
        button {
            width: 28%;
            padding: 4px 8px;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }

        .btn-mini {
            width: 30%;
        }
    }
}

.overlay-modal {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba($black, 0.5);
    ::-webkit-scrollbar { width: 6px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }
}

.modal-delete {
	@include modal-container;
    width: 40%;
}

/* ACCIONES MODAL */

.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-modal {
	opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
/*----------------------------------------------------------------*/

.modal-full {
  .label {
      min-width: 23%;
      max-width: 24%;
  }
}
.modal-alert {
    width: 40%;

    button {
        padding: 10px 22px;
    }
}

.modal-subfrac {
    .label {
        min-width: 15%;
        max-width: 15%;
    }
}

.modal-scroll {
    overflow-y: scroll;
    height: 500px;
}

.modal-content {
    width: 100%;
    padding: 30px;
    overflow: auto;
    max-height: 700px;
}

.modal-content-incident {
    width: 100%;
    padding: 30px;
    overflow: auto;
    max-height: 700px;
}

.buttons {
    padding-bottom: 16px;
}

.modal-owners {
    .search-content {
        .left {
            width: 85%;
        }

        flex-direction: row-reverse;
        .search-cancel {
            padding: 20px 0px 0 8px;
            //width: 15%;
        }
    }
   
    .label {
        min-width: 20%;
        max-width: 20%;
    }

    .column-sub {
        margin-left: 24px;
        width: 90%;
    }

    .label-small {
        min-width: 40%;
        max-width: 40%;
    }

    .modal-content {
		overflow-y: scroll;
        .option {
            cursor: pointer;
            padding: 8px 0;
            border-top: 1px solid black;
            border-left: 1px solid black !important;
			border-right: 1px solid black;

            .butn {
                cursor: pointer;
                font-family: $Mukta;
                font-size: $fontSmall;
                font-weight: $weightSemi;
                transition: all 200ms ease-in-out;
                &:active { transform: scale(0.95); }
            }
        }

        .no-select {
            background: #f1f1f1;
            cursor: pointer;
            border-left: 2px solid white;

            .butn {
                cursor: pointer;
                font-family: $Mukta;
                font-size: $fontSmall;
                font-weight: $weightSemi;
                transition: all 200ms ease-in-out;
                &:active { transform: scale(0.95); }
            }

            &:hover {
                border: 1px solid;
            }
        }

        .menu {
            /*#ea4235*/
            background: $primary;
            border: 1px solid black;
        }
        .menu2{
            border-bottom: 2px solid rgba(108, 117, 125, 0.4);
        }

        .container-complete {
			//border: 1px solid black;
			max-height: 300px;
			overflow-y: scroll;
        }
    }
}

.modal-houses {
    p {
        min-width: 20%;
    }
    .inquilino-name {
        width: 65%;
    }
    .inquilino-btns {
        width: 35%;
    }
}


.small-modal {
    top: 50%;
    left: 50%;
    width: 40%;
    outline: none;
    max-height: 90%;
    transition: max-height 1.5s ease-in-out;
    position: absolute;
    border-radius: 4px;
    background-color: #FFFFFF;
    transform: translate(-50%, -50%);
    background-color: white;

    .search-cancel-so {
        padding-left: 6px;
        width: 20%;

        .btn-secondary {
            margin-top: 4px;
        }
    } 
}

.image-modal {
    background-color: transparent !important;
    padding: 30px;
    top: 45%;
    .header-modals {
        background-color: transparent !important;
    }
    img {
        width: 390px;
        height: auto;
    }
}


