.tooltip {
	position: relative;
	display: inline-block;
  
	.tooltiptext {
	  visibility: hidden;
	  width: auto;
	  background-color: rgba(0, 0, 0, 0.776);
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding: 5px 10px;
  
	  /* Position the tooltip */
	  position: absolute;
	  z-index: 1;
	  top: -5px;
	  left: 80%;
  
	  &::after {
		content: "";
		position: absolute;
		top: 27%;
		left: -28%;
		margin-left: -5px;
		border-width: 7px;
		border-style: solid;
		border-color: transparent rgba(0, 0, 0, 0.776) transparent transparent;
	  }
	}
  
	&:hover .tooltiptext {
	  visibility: visible;
	}
  }