/**
*   inputs.scss
*   @description: Estilos para los inputs
*/

input, select, option, textarea {
    font-family: $Mukta;
}

select:disabled {
    background-color: #EBEBE4;
}

.input {
    color: $dark;
    padding: $paddingNormal;
    border-bottom: 2px solid rgba($gray, 0.5);
    &:focus { border-bottom: 2px solid $primary; }
}

/*-------- Input modales ---------- */

.input-modals {
    width: 100%;
	padding: 4px 2px;
	font-size: $fontText;
}

/*----------------------------------*/

.search-column {
	position: relative;
	.btn-buscar {
		position: absolute;
		right: 2px;
	}
}
.input-select {
    border-radius: 4px;
    padding: $paddingNormal;
    font-weight: $weightSemi;
}

.incidents .input-select, .guests .input-select {
    width: 200px;
    margin-left: $marginMedium;
}

.textarea {
    resize: none;
    line-height: 24px;
    padding: $paddingNormal;
    background-color: #f2f2f3;
}

.radio {    
    top: 0;
    border: 0;
    height: 20px;
    width: 20px;
    outline: none;
    cursor: pointer;     
    border-radius: 50px;
    color: #666666;
    background-color: #f1f1f1;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    margin-right: $marginMedium;
    transition: all 300ms ease-in-out;
}
.radio:checked::before {
    left: 6px;
    font-size: 0.8rem;
    content: '\02143';
    position: absolute;
    transform: rotate(40deg);
}

.radio:hover { background-color: #dedede; }

.radio:checked {
    color: $white;
    background-color: $primary;
}

.radio-label {
    color: $dark;
    cursor: pointer;
    font-family: $Mukta;
    font-size: $fontSmall;
    margin-right: $marginMedium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.input-filter {
    padding: $paddingNormal;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.input-modals-small {
    width: 40%;
    padding: 4px 2px;
}

.modal-incidents #incident-resolution {
    border-radius: 0;
    background-color: #f2f2f3;
}

.input-year {
    width: 30%;
    padding: 4px $paddingNormal;
    margin-left: $marginMedium;
}
.modal-houses .input {
    width: 100%;
    margin-left: $marginNormal;
}

.input-description {
    border: 1px solid rgb(59, 59, 59);
}

.modal-houses .input-select-type {
    width: 75%;
}

.width-45 {
    width: 45%;
}

.input-clean {
	position: relative;
	input {
		padding-right: 25px;
	}
	i {
		height: 100%;
		right: 10px;
		top: 10px;
		position: absolute;
		cursor: pointer;
		z-index: 2;
	}
}

/* The container */
.container-check {
	display: block;
	height:25px;
	width:25px;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: rgb(211, 211, 211);
  }
  
  /* On mouse-over, add a grey background color */
  .container-check:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .checkmark {
	background-color: #ea4235;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .container-check input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-check .checkmark:after {
	left: 9px;
	top: 5px;
	width: 8px;
    height: 13px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }

  .input-select-configuration {
      width: 20%;
      padding: 0;
      border-radius: 2px;
      border: 1px solid rgba(159, 159, 159, 0.5);
  }